import React, { useState } from "react"
import styled from "styled-components"
import useInterval from "../../../utils/useInterval"
import zeusApi from "../../../api/zeus"
import Button from "../../Button"
import { motion } from "framer-motion"

const Container = styled.div`
  padding: 64px 296px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gradient {
    background: linear-gradient(135deg, #ff8849 0%, #ffcb15 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .progress-bar {
    position: relative;
    height: 12px;
    width: 100%;
    border-radius: 40px;
    background: rgba(243, 243, 243, 0.5);
  }

  .filler {
    height: 100%;
    border-radius: inherit;
    transition: width 1s linear;
  }

  .current-point-container {
    background: #2e2e2e;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 12px 12px;
    align-self: flex-end;
    margin-bottom: 12px;
    width: 60px;

    h4 {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 8px;
      background: linear-gradient(135deg, #ff8849 0%, #ffcb15 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .correct-point-container {
    background: #efb567;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 12px 12px;
    align-self: flex-end;
    margin-bottom: 12px;
    width: 60px;
    position: absolute;
    z-index: 10;

    h4 {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 8px;
      color: #000000;
      font-family: "DM Sans", sans-serif;
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  img {
    width: 100%;
    margin-bottom: 72px;
  }
`

const AnswerQuestion = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #dddddd;
  border-radius: 5px;
  padding: 24px 0px;
  width: 100%;
  margin-bottom: 24px;
  max-height: 80px;
  cursor: pointer;
  color: #000000;

  h4 {
    margin: 0;
  }
`

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background-color: #000000;
  border: 2px solid
    ${(props) =>
      props.state === "correct"
        ? "#0ACF83"
        : props.state === "wrong"
        ? "#EB5757"
        : "#f3f3f3"};
  padding: 16px 24px;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  margin-bottom: 32px;

  input {
    width: ${(props) =>
      props.state !== "correct" || props.state !== "wrong" ? "100%" : "95%"};
    background-color: #000000;
    border: none;
    color: ${(props) =>
      props.state === "correct"
        ? "#0ACF83"
        : props.state === "wrong"
        ? "#EB5757"
        : "#ffffff"};
    font-size: 18px;
    font-weight: 600;
    font-family: "itc", sans-serif;
  }
`

const CurrentPoint = ({ point }) => {
  return (
    <div className="current-point-container">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill="url(#paint0_linear)" />
        <path
          d="M12.3263 6.3363C12.8863 6.8163 13.1663 7.4963 13.1663 8.3763C13.1663 9.2563 12.8863 9.9363 12.3263 10.4163C11.9743 10.7203 11.6143 10.9163 11.2463 11.0043C10.8863 11.0843 10.4143 11.1243 9.83028 11.1243H9.13428V14.5083H7.49028V5.6283H9.83028C10.4143 5.6283 10.8863 5.6723 11.2463 5.7603C11.6143 5.8403 11.9743 6.0323 12.3263 6.3363ZM11.3543 9.0243C11.4663 8.8483 11.5223 8.6323 11.5223 8.3763C11.5223 8.1203 11.4703 7.9083 11.3663 7.7403C11.2703 7.5643 11.1263 7.4403 10.9343 7.3683C10.7423 7.2883 10.5543 7.2363 10.3703 7.2123C10.1863 7.1803 9.97028 7.1643 9.72228 7.1643H9.13428V9.5883H9.66228C9.91828 9.5883 10.1383 9.5763 10.3223 9.5523C10.5063 9.5203 10.6983 9.4683 10.8983 9.3963C11.0983 9.3163 11.2503 9.1923 11.3543 9.0243Z"
          fill="black"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="0"
            y1="0"
            x2="20"
            y2="20"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF8849" />
            <stop offset="1" stopColor="#FFCB15" />
          </linearGradient>
        </defs>
      </svg>
      <h4>{point}</h4>
    </div>
  )
}

const YellowPoint = ({ point, type, translateY }) => {
  return (
    <motion.div
      className="correct-point-container"
      initial={{ translateY: 0, opacity: 0 }}
      animate={{ translateY: translateY, opacity: 1 }}
    >
      {type === "correct" ? (
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5585 20C5.23343 20 0.916626 15.6832 0.916626 10.3582C0.916626 5.03311 5.23343 0.716309 10.5585 0.716309C15.8835 0.716309 20.2003 5.03311 20.2003 10.3582C20.2003 15.6832 15.8835 20 10.5585 20ZM10.5585 18.2471C14.9153 18.2471 18.4473 14.7152 18.4473 10.3583C18.4473 6.00146 14.9153 2.46953 10.5585 2.46953C6.20162 2.46953 2.66969 6.00146 2.66969 10.3583C2.66969 14.7152 6.20162 18.2471 10.5585 18.2471ZM13.4448 7.10867L8.80541 11.7481L6.79562 9.73826L5.55602 10.9779L8.80541 14.2273L14.6844 8.34827L13.4448 7.10867Z"
            fill="black"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.17257 2.2559L3.99406 1.07739L1.07739 3.99406L2.2559 5.17257L5.17257 2.2559ZM14.8274 2.2559L16.0059 1.07739L18.9226 3.99406L17.7441 5.17257L14.8274 2.2559ZM9.99998 18.3333C5.39761 18.3333 1.66665 14.6023 1.66665 9.99993C1.66665 5.39755 5.39761 1.66659 9.99998 1.66659C14.6024 1.66659 18.3333 5.39755 18.3333 9.99993C18.3333 14.6023 14.6024 18.3333 9.99998 18.3333ZM9.99998 16.6667C13.6819 16.6667 16.6666 13.6819 16.6666 10C16.6666 6.31812 13.6819 3.33335 9.99998 3.33335C6.31808 3.33335 3.33332 6.31812 3.33332 10C3.33332 13.6819 6.31808 16.6667 9.99998 16.6667ZM14.1666 9.16673H10.8333V5.00006H9.16665V10.8334H14.1666V9.16673Z"
            fill="black"
          />
        </svg>
      )}
      <h4>
        {type === "time" && "-"} {point}
      </h4>
    </motion.div>
  )
}

const ProgressBar = ({ percentage }) => {
  const color = (percentage) => {
    if (percentage >= 50) {
      return "#0ACF83"
    } else if (percentage >= 10) {
      return "#FDD262"
    } else {
      return "#EB5757"
    }
  }
  return (
    <div className="progress-bar">
      <div
        className="filler"
        style={{ width: `${percentage}%`, background: color(percentage) }}
      />
    </div>
  )
}

const Input = ({ state, setAnswer, value }) => {
  return (
    <InputContainer state={state}>
      <input
        placeholder="Type your answer here"
        onChange={(e) => setAnswer(e.target.value)}
        value={value}
      />
      {state === "correct" && (
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4998 22.9583C5.17157 22.9583 0.0415039 17.8282 0.0415039 11.5C0.0415039 5.1717 5.17157 0.041626 11.4998 0.041626C17.8281 0.041626 22.9582 5.1717 22.9582 11.5C22.9582 17.8282 17.8281 22.9583 11.4998 22.9583ZM11.4998 20.875C16.6775 20.875 20.8748 16.6776 20.8748 11.5C20.8748 6.32231 16.6775 2.12498 11.4998 2.12498C6.32217 2.12498 2.12484 6.32231 2.12484 11.5C2.12484 16.6776 6.32217 20.875 11.4998 20.875ZM14.93 7.63842L9.41658 13.1519L7.02814 10.7634L5.55501 12.2366L9.41658 16.0981L16.4031 9.11156L14.93 7.63842Z"
            fill="#0ACF83"
          />
        </svg>
      )}
      {state === "wrong" && (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5C25 19.4036 19.4036 25 12.5 25ZM12.5001 22.7273C18.1485 22.7273 22.7274 18.1483 22.7274 12.5C22.7274 6.85161 18.1485 2.27271 12.5001 2.27271C6.85176 2.27271 2.27286 6.85161 2.27286 12.5C2.27286 18.1483 6.85176 22.7273 12.5001 22.7273ZM8.75803 17.849L12.5 14.1071L16.2419 17.849L17.8489 16.242L14.107 12.5L17.8489 8.75812L16.2419 7.15106L12.5 10.893L8.75803 7.15106L7.15097 8.75812L10.8929 12.5L7.15097 16.242L8.75803 17.849Z"
            fill="#EB5757"
          />
        </svg>
      )}
    </InputContainer>
  )
}

const Question = ({
  length,
  index,
  title,
  initialCount,
  sessionId,
  choices,
  point,
  ...props
}) => {
  const [count, setCount] = useState(initialCount)
  const [feedback, setFeedback] = useState()
  const [answer, setAnswer] = useState("")
  const [correctPoint, setCorrectPoint] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [duration, setDuration] = useState(Date.now())
  const [stop, setStop] = useState(false)
  const [state, setState] = useState("")
  const [answered, hasAnswered] = useState(false)

  const submitAnswer = (answer) => {
    /* eslint-disable camelcase */
    const timeDiff = Math.floor((Date.now() - duration) / 1000)
    hasAnswered(true)
    setDuration(timeDiff)
    setStop(true)
    setIsLoading(true)
    const body = { time: timeDiff, answer: answer, question_number: index }
    setAnswer(answer)
    zeusApi.playground.postAnswer(sessionId, body).then((result) => {
      if (result.correct_answer.toLowerCase() === answer.toLowerCase()) {
        setState("correct")
      } else {
        setState("wrong")
      }
      setFeedback(result)
      props.setPoint(result.total_score)
      setCorrectPoint(result.score)
      setIsLoading(false)
    })
  }

  useInterval(() => {
    if (!stop) {
      if (count > 0) {
        setCount(count - 1)
      } else if (count === 0 && index + 1 !== length) {
        submitAnswer("")
        if (typeof feedback !== "undefined") {
          props.setStatus("LOADER_NEW_QUESTION")
        }
      } else if (count === 0 && index + 1 === length) {
        submitAnswer("")
        if (typeof feedback !== "undefined") {
          props.setStatus("END")
        }
      }
    } else {
      if (!isLoading) {
        setTimeout(() => {
          props.setStatus(index + 1 !== length ? "LOADER_NEW_QUESTION" : "END")
        }, 2000)
      }
    }
  }, 1000)

  return (
    <Container>
      <CurrentPoint point={point} />
      {feedback && state === "correct" && (
        <YellowPoint point={correctPoint} type="correct" translateY={50} />
      )}
      {feedback && state === "correct" && (
        <YellowPoint point={duration} type="time" translateY={100} />
      )}
      <ProgressBar percentage={count} />
      <h3 className="gradient">
        Question {index + 1} of {length}
      </h3>
      {props.type === "MultipleChoiceQuestion" ? (
        <h1>{title}</h1>
      ) : (
        <img src={title} />
      )}
      {!isLoading &&
        props.type === "MultipleChoiceQuestion" &&
        choices.map((choice) => (
          <AnswerQuestion
            key={choice.id}
            onClick={!answered ? () => submitAnswer(choice.answer) : ""}
            style={{
              background:
                feedback?.correct_answer.toLowerCase() ===
                choice.answer.toLowerCase()
                  ? "#0ACF83"
                  : choice.answer.toLowerCase() === answer.toLowerCase()
                  ? "#EB5757"
                  : "#DDDDDD",
              color:
                feedback?.correct_answer.toLowerCase() ===
                  choice.answer.toLowerCase() ||
                choice.answer.toLowerCase() === answer.toLowerCase()
                  ? "#fff"
                  : "#000",
              border:
                feedback?.correct_answer.toLowerCase() ===
                  choice.answer.toLowerCase() ||
                choice.answer.toLowerCase() === answer.toLowerCase()
                  ? "2px solid #F3F3F3"
                  : "none",
              visibility:
                answer === ""
                  ? "visible"
                  : feedback?.correct_answer.toLowerCase() ===
                      choice.answer.toLowerCase() ||
                    choice.answer.toLowerCase() === answer.toLowerCase()
                  ? "visible"
                  : "hidden",
            }}
          >
            <h4>{choice.answer}</h4>
          </AnswerQuestion>
        ))}
      {!isLoading && props.type === "SimpleTextQuestion" && (
        <Input
          state={state}
          setAnswer={setAnswer}
          value={
            state === "correct"
              ? "Correct Answer"
              : state === "wrong"
              ? "Wrong Answer"
              : answer
          }
          disabled={!!(state === "correct" || state === "wrong")}
        />
      )}
      {!isLoading && props.type === "SimpleTextQuestion" && state === "wrong" && (
        <div style={{ width: "100%", display: "flex" }}>
          <h4 style={{ color: "#0ACF83", fontWeight: "600", size: "18px" }}>
            Correct answer: {feedback?.correct_answer}
          </h4>
        </div>
      )}
      {!isLoading && props.type === "SimpleTextQuestion" && !answered && (
        <Button onClick={() => submitAnswer(answer)}>Submit</Button>
      )}
      {isLoading && count !== 0 && <h2>Verifying...</h2>}
    </Container>
  )
}

export default Question
