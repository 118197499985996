import React, { useEffect, useState } from "react"
import QuestionLoader from "../../components/PlaygroundPage/Quiz/QuestionLoader"
import CircularProgressBar from "../../components/PlaygroundPage/Quiz/CircularProgressBar"
import Question from "../../components/PlaygroundPage/Quiz/Question"
import FinishQuiz from "../../components/PlaygroundPage/Quiz/FinishQuiz"
import "react-circular-progressbar/dist/styles.css"
import styled from "styled-components"
import useInterval from "../../utils/useInterval"
import zeusApi from "../../api/zeus"
import Button from "../../components/Button"

const Container = styled.div`
  height: 100vh;
  .wrapper {
    width: 264px;
    height: 264px;
  }
  h1 {
    text-align: center;
  }
`

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`

const Quiz = () => {
  const [count, setCount] = useState(100)
  const [start, setStart] = useState(75)
  const [status, setStatus] = useState("WAITING")
  const [questionList, setQuestionList] = useState()
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [point, setPoint] = useState(0)
  const [companySlug, setCompanySlug] = useState("")
  const [quizSessionId, setQuizSessionId] = useState()

  useInterval(() => {
    if (
      typeof questionList !== "undefined" &&
      status === "WAITING" &&
      start !== 0
    ) {
      setStart(start - 1)
    } else if (status === "WAITING" && start === 0) {
      setStatus("SHOW_LOADER")
    } else if (status === "SHOW_LOADER" && count !== 0) {
      setCount(count - 1)
    } else if (status === "SHOW_LOADER" && count === 0) {
      setStatus("SHOW_QUESTION")
      setCount(100)
    }
  }, 50)

  useEffect(() => {
    if (window) {
      const urlParams = new URLSearchParams(window.location.search)
      const sessionId = urlParams.get("session")
      setQuizSessionId(sessionId)
      const tmpSlug = urlParams.get("slug")
      setCompanySlug(tmpSlug)
      zeusApi.playground.getQuizQuestion(sessionId).then((result) => {
        const question = result.question.sort((q1, q2) =>
          q1.question_number > q2.question_number ? 1 : -1
        )
        setQuestionList(question)
        setCurrentQuestion(result.current_number)
        if (result.current_number >= question.length) {
          setStatus("FINISH")
        }
      })
    }
  }, [])

  return (
    <Container>
      {status === "WAITING" && (
        <CenterContainer>
          <h1>Answer the questions before the time runs out!</h1>
        </CenterContainer>
      )}
      {status === "SHOW_LOADER" && (
        <CircularProgressBar
          percentage={count}
          startColor="#EC006A"
          endColor="#FFCB15"
          gradientId="progress"
        />
      )}
      {status === "SHOW_QUESTION" && (
        <Question
          length={questionList?.length}
          title={questionList[currentQuestion]?.question}
          choices={questionList[currentQuestion]?.choices}
          duration={questionList[currentQuestion]?.duration}
          index={currentQuestion}
          initialCount={100}
          point={point}
          setStatus={setStatus}
          setPoint={setPoint}
          sessionId={quizSessionId}
          type={questionList[currentQuestion]?.type}
        />
      )}
      {status === "LOADER_NEW_QUESTION" && (
        <QuestionLoader
          setStatus={setStatus}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
        />
      )}
      {status === "END" && (
        <FinishQuiz finalScore={point} companySlug={companySlug} />
      )}
      {status === "FINISH" && (
        <CenterContainer>
          <h1>You have finished the Quiz challenge</h1>
          <Button to={`/playground/${companySlug}`}>Back</Button>
        </CenterContainer>
      )}
    </Container>
  )
}

export default Quiz
