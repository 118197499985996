/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import styled from "styled-components"
import Image from "../../image"
import ProgressBar from "../../ProgressBar"
import useInterval from "../../../utils/useInterval"
import { graphql, useStaticQuery } from "gatsby"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 392px;
  text-align: center;

  .did-you-know {
    background: linear-gradient(135deg, #ec006a 0%, #ffcb15 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    margin-bottom: 84px;
  }

  .fun-fact {
    margin-top: 48px;
    color: #ffcb15;
    margin-bottom: 80px;
  }

  .eve-wrapper {
    width: 100%;
    max-width: 240px;
  }

  @media screen and (max-width: 1200px) {
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;

    .did-you-know {
      margin-bottom: 42px;
    }

    .fun-fact {
      margin-top: 48px;
      color: #ffcb15;
      margin-bottom: 40px;
    }
  }
`

const QuestionLoader = ({ ...props }) => {
  const { allContentfulFunFact } = useStaticQuery(
    graphql`
      query {
        allContentfulFunFact {
          nodes {
            deskripsi {
              deskripsi
            }
          }
        }
      }
    `
  )

  const funFacts = allContentfulFunFact.nodes

  const [fact, setFact] = useState(
    funFacts[Math.floor(Math.random() * funFacts.length)]
  )

  const [count, setCount] = useState(100)

  useInterval(() => {
    if (count > 0) {
      setCount(count - 1)
    } else {
      props.setStatus("SHOW_QUESTION")
      props.setCurrentQuestion(props.currentQuestion + 1)
    }
  }, 50)

  return (
    <Container>
      <h1 className="did-you-know">Did you know?</h1>
      <div className="eve-wrapper">
        <Image imgName="eve_merah.png" alt="Eve" />
      </div>
      <h4 className="fun-fact">{fact.deskripsi.deskripsi}</h4>
      <ProgressBar
        completed={count}
        fillerColor="linear-gradient(135deg, #EC006A 0%, #F66C3D 100%)"
      />
    </Container>
  )
}

export default QuestionLoader
