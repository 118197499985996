import React from "react"
import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  .wrapper {
    width: 264px;
    height: 264px;
  }
`
const CircularProgressBar = ({
  percentage,
  startColor,
  endColor,
  gradientId,
}) => {
  const gradientTransform = `rotate(135)`

  return (
    <Container>
      <div className="wrapper">
        <svg style={{ height: 0, width: 0 }}>
          <defs>
            <linearGradient
              id={gradientId}
              gradientTransform={gradientTransform}
            >
              <stop offset="0%" stopColor={startColor} />
              <stop offset="100%" stopColor={endColor} />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgressbar
          value={percentage}
          text={`${Math.ceil(percentage / 25)}`}
          counterClockwise
          styles={{
            path: {
              stroke: `url(#${gradientId})`,
            },
            text: {
              fill: "white",
              fontWeight: "bold",
            },
          }}
        />
      </div>
    </Container>
  )
}

export default CircularProgressBar
