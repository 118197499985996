import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Button from "../../Button"
import InformationBlackscreen from "../../DetailPerusahaan/InformationBlackscreen"
import vectorSuccesful from "../../DetailPerusahaan/PerusahaanChallenge/vector_successful.svg"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  .quiz-completed {
    background: linear-gradient(135deg, #ec006a 0%, #ffcb15 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    margin-bottom: 36px;
  }

  .final-score {
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .point {
    background: linear-gradient(135deg, #ff8849 0%, #ffcb15 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 48px;
  }
`

const CompletedUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 0 3rem;

  .image__placeholder {
    margin-bottom: 3rem;
  }

  .completed__text {
    color: #0acf83;
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }

  .completed__description {
    font-size: 1.125rem;
  }

  @media (max-width: 768px) {
    .image__placeholder {
      width: 164px;
      height: 164px;
      margin-bottom: 2.25rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .completed__text {
      margin-bottom: 0.5rem;
    }
    .completed__description {
      font-size: 1rem;
    }
  }
`

const FinishQuiz = ({ finalScore, companySlug }) => {
  const [showSuccessful, setShowSuccessful] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setShowSuccessful(false)
    }, 2000)
  }, [])

  return showSuccessful ? (
    <InformationBlackscreen>
      <CompletedUpload>
        <div className="image__placeholder">
          <img src={vectorSuccesful} alt="succesful" />
        </div>
        <h1 className="completed__text">Congratulations!</h1>
        <h2 className="completed__description">
          You have successfully completed the Quiz challenge{" "}
        </h2>
      </CompletedUpload>
    </InformationBlackscreen>
  ) : (
    <Container>
      <h1 className="quiz-completed">Quiz Completed</h1>
      <p className="final-score">Your final score is</p>
      <h1 className="point">{finalScore}</h1>
      <Button to={`/playground/${companySlug}`}>Claim Points</Button>
    </Container>
  )
}

export default FinishQuiz
